import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';

import { ReactComponent as Print } from '../../../assets/images/gpr/result/print.svg';
import { ReactComponent as Save } from '../../../assets/images/gpr/result/save.svg';

import ResultTablePrint from '../ResultTablePrint/ResultTablePrint.component';
import ResultTableDownload from '../ResultTableDownload/ResultTableDownload.component';

import styles from './ResultActionDropdown.module.scss';

const ResultActionDropdown = ({ data, onDownloadCSV }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handleSelectOption = ({ key }) => {
    if (key === 'csv') {
      onDownloadCSV();
    } else if (key === 'print') {
      handlePrint();
    }
  };

  const menu = (
    <Menu
      onClick={handleSelectOption}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        width: '180px',
        padding: '15px',
        position: 'relative',
        left: 0,
        top: 0,
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '5px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#303134'
      }}>
      <MenuItem style={{ marginBottom: '3px' }} key="csv">
        <div className={styles.menuItem}>
          <Save />
          Download CSV
          <div style={{ display: 'none' }}>
            <ResultTablePrint ref={componentRef} reportData={data} />
          </div>
        </div>
      </MenuItem>
      <MenuItem key="pdf">
        <ResultTableDownload classNameBtn={styles.menuItem} reportData={data}>
          <Save />
          <div>Download PDF</div>
        </ResultTableDownload>
      </MenuItem>
      <MenuItem key="print">
        <div className={styles.menuItem}>
          <Print />
          Print
        </div>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={styles.dropdownContainer}>
      <Dropdown
        getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
        trigger={['click']}
        overlay={menu}>
        <button className={styles.downloadBtn}>
          <Save />
          Download file
        </button>
      </Dropdown>
    </div>
  );
};

export default ResultActionDropdown;

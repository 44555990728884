import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReports, createReport } from '../../../store/actions';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

import { CompletedReportsTable } from '../../../components/BatchProcessor/CompletedReportsTable/CompletedReportsTable.component';
import FAQComponent from '../../../components/FAQComponent/FAQComponent.component';
import { batchProcessorFAQ } from '../../../components/FAQComponent/FAQComponent.data';
import TitleBadge from '../../../components/TitleBadge/TitleBadge.component';
import styles from './CompletedReports.module.scss';

import Background from '../../../assets/images/Background.png';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';

import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CompletedReports = () => {
  const [isLoading, setIsLodaing] = useState(false);
  const [isEditSubmited, setIsEditSubmited] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showLessItems: true
    }
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);
  const { history } = useSelector((state: IRootState) => state.batchAnalysisReducer);
  const rows = Object.values(history)
    .filter((item) => item.id !== 'new')
    .map((item) => {
      return { ...item, key: item.id };
    });

  const fetchData = () => {
    if (tableParams?.pagination?.current && keyIsLoaded) {
      setIsLodaing(true);
      fetch(`${ENVS.PROD}/user/findAll/${apiKey}?page=${tableParams.pagination.current}`)
        .then((res) => res.json())
        .then((results) => {
          dispatch(fetchReports(results.reports));
          setIsLodaing(false);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: results.totalReports
            }
          });
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams.pagination?.current), isEditSubmited]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter
    });
  };

  const handleLoading = () => {
    setIsEditSubmited((state) => !state);
  };

  const handleNewBatchReportClick = () => {
    dispatch(createReport({ id: 'new', title: '' }));
    navigate('new');
  };

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${Background}), linear-gradient(180deg, #DFE8F2 7.67%, #F4F5F7 100%)`
        }}>
        <div className={styles.contentContainer}>
          <div className={styles.contentHeader}>
            <div className={styles.contentHeaderContainer}>
              <h2 className={styles.contentHeaderTitle}>
                Rentometer Batch Processor
                <TitleBadge text={'BETA'} />
              </h2>
              <div className={styles.contentHeaderSubTitle}>
                Receive rent prices for up to 500 properties
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.headerContainer}>
                <h1 className={styles.headerTitle}>Completed Batches</h1>
              </div>
              <button className={styles.headerButton} onClick={handleNewBatchReportClick}>
                Build New Batch
              </button>
            </div>

            {isLoading ? (
              <div style={{ margin: '150px 0' }} className="spiner-container">
                <img src={spiner} alt="loading.." />
              </div>
            ) : (
              <CompletedReportsTable
                rows={rows}
                columns={null}
                handleLoading={handleLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classNames(styles.container, styles.grayContainer)}>
        <div className={styles.contentContainer}>
          <FAQComponent data={batchProcessorFAQ} />
        </div>
      </div>
    </>
  );
};
export default CompletedReports;

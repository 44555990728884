import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFDownloadLink
} from '@react-pdf/renderer';

import logo from '../../../assets/images/RentometerLogo.png';
import { ReactComponent as Save } from '../../../assets/images/gpr/result/save.svg';
import regular from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import medium from '../../../assets/fonts/Roboto/Roboto-Medium.ttf';
import bold from '../../../assets/fonts/Roboto/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: regular },
    { src: medium, fontWeight: 'medium' },
    { src: bold, fontWeight: 'bold' }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  container: {
    width: '100%',
    margin: 30,
    color: '#2D3035'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '40px'
  },
  headerLogo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  headerLogoText: {
    fontWeight: 'normal',
    fontSize: '10px',
    color: '#717780',
    paddingLeft: '2px'
  },
  image: {
    display: 'flex',
    width: '85px',
    marginBottom: '6px'
  },
  headerDescription: {
    width: '180px',
    borderBottom: '1px',
    borderBottomStyle: 'solid',
    borderColor: '#EF6F22'
  },
  headerName: {
    display: 'flex',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: '16px',
    paddingBottom: '2px'
  },
  headerType: {
    display: 'flex',
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: 'normal',
    marginBottom: '6px'
  },
  aside: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#F8FAFD',
    padding: '17px 16px',
    borderRadius: '8px',
    marginBottom: '30px'
  },
  asideItem: {
    width: '104px',
    height: '61px'
  },
  asideValue: {
    position: 'relative',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 'medium',
    marginBottom: '16px'
  },
  asideDescription: {
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 'normal',
    color: '#717780'
  },
  lables: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2D3035',
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '12px 0px'
  },
  labelNumber: {
    marginLeft: '15px',
    width: '62px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelAddress: {
    marginLeft: '7px',
    width: '275px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelBa: {
    marginLeft: '7px',
    width: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelBr: {
    marginLeft: '7px',
    width: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelRent: {
    marginLeft: '7px',
    width: '88px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px'
  },
  tableItem: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2D3035',
    fontSize: '10px',
    fontWeight: 'normal',
    padding: '12px 0px'
  },
  tableNumber: {
    marginLeft: '15px',
    width: '62px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableAddress: {
    marginLeft: '7px',
    width: '275px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableBa: {
    marginLeft: '7px',
    width: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableBr: {
    marginLeft: '7px',
    width: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableRent: {
    marginLeft: '7px',
    width: '88px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  totalItem: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2D3035',
    fontSize: '10px',
    fontWeight: 'normal',
    padding: '12px 0px',
    backgroundColor: '#E9EDF3'
  },

  multiLabelNumber: {
    marginLeft: '15px',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multiLabelAddress: {
    marginLeft: '7px',
    width: '123px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multiLabelBa: {
    marginLeft: '7px',
    width: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multiLabelBr: {
    marginLeft: '7px',
    width: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multiLabelRent: {
    marginLeft: '7px',
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multiLabelCount: {
    marginLeft: '7px',
    width: '84px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  multiLabelTrent: {
    marginLeft: '7px',
    width: '74px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableItemGray: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2D3035',
    fontSize: '10px',
    fontWeight: 'normal',
    padding: '12px 0px',
    backgroundColor: '#F8FAFD'
  },
  errMessage: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'medium',
    marginTop: '50px'
  },
  multiAddress: {
    fontSize: '10px',
    fontWeight: 'normal',
    marginBottom: '15px'
  },
  multiAddressBold: {
    fontWeight: 'bold',
    paddingRight: '5px'
  }
});

const ResultTableDownload = ({ classNameBtn, reportData, children }) => {
  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');
  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
  const generateTimestamp = () => {
    let date = new Date();
    return `${
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear() +
      ', ' +
      formatAMPM(date)
    }`;
  };
  const generateTitle = () => {
    let date = new Date();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    return `GPR_${year}${month}${day}_${hour}${minute}${second}`;
  };

  const singleReport = reportData?.data?.map((item, i) => {
    return (
      <View style={(i + 1) % 2 === 0 ? styles.tableItem : styles.tableItemGray} key={i}>
        <Text style={styles.tableNumber}>{i + 1}</Text>
        <Text style={styles.tableAddress}>{item.address}</Text>
        <Text style={styles.tableBr}>{item.isComplete ? item.bedrooms : '-'}</Text>
        <Text style={styles.tableBa}>{item.isComplete ? item.bathrooms : '-'}</Text>
        {/*<Text style={styles.tableRent}>*/}
        {/*  {item.isComplete && item.currentRent ? `$${formatNumber(item.currentRent)}` : '-'}*/}
        {/*</Text>*/}
        <Text style={styles.tableRent}>
          {item.isComplete ? `$${formatNumber(item.mean)}` : '-'}
        </Text>
      </View>
    );
  });

  const multiReport = reportData?.data?.map((item, i) => {
    return (
      <View style={(i + 1) % 2 === 0 ? styles.tableItem : styles.tableItemGray} key={i}>
        <Text style={styles.multiLabelNumber}>{i + 1}</Text>
        <Text style={styles.multiLabelAddress}>{item.description}</Text>
        <Text style={styles.multiLabelBr}>{item.isComplete ? item.bedrooms : '-'}</Text>
        <Text style={styles.multiLabelBa}>{item.isComplete ? item.bathrooms : '-'}</Text>
        <Text style={styles.multiLabelRent}>${formatNumber(item.mean)}</Text>
        <Text style={styles.multiLabelCount}>{item.isComplete ? item.count : '-'}</Text>
        <Text style={styles.multiLabelTrent}>${formatNumber(item.multiTotalRent)}</Text>
      </View>
    );
  });

  const pdf = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLogo}>
              <Image src={logo} style={styles.image} />
              <Text style={styles.headerLogoText}>{generateTimestamp()}</Text>
            </View>
            <View style={styles.headerDescription}>
              <Text style={styles.headerName}>GPR Calculator</Text>
              <Text style={styles.headerType}>
                {reportData?.reportType === 'single'
                  ? 'Single-Family Houses'
                  : 'Multi-Family Building'}
              </Text>
            </View>
          </View>
          <View style={styles.aside}>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>${formatNumber(reportData?.monthGPR)}</Text>
              <Text style={styles.asideDescription}>Monthly Gross Potential Rent (GPR)</Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>
                ${formatNumber(reportData?.estimatedPropertyValue)}
              </Text>
              <Text style={styles.asideDescription}>Estimated Portfolio Value (1% rule)</Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>{formatNumber(reportData?.numberOfItems)}</Text>
              <Text style={styles.asideDescription}>
                Number of {reportData?.reportType === 'single' ? 'houses' : 'units'} calculated
              </Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>${formatNumber(reportData?.averageRentForItem)}</Text>
              <Text style={styles.asideDescription}>
                Average rent for one {reportData?.reportType === 'single' ? 'house' : 'unit'}
              </Text>
            </View>
          </View>
          {reportData?.reportType === 'single' ? (
            <>
              <View style={styles.lables}>
                <Text style={styles.labelNumber}>№</Text>
                <Text style={styles.labelAddress}>Address</Text>
                <Text style={styles.labelBr}>BR</Text>
                <Text style={styles.labelBa}>BA</Text>
                {/*<Text style={styles.labelRent}>Current Rent</Text>*/}
                <Text style={styles.labelRent}>Average Rent</Text>
              </View>
              <View style={styles.table}>{singleReport}</View>
              <View>
                <View style={styles.lables}>
                  <Text style={styles.labelNumber}>Total</Text>
                </View>
                <View style={styles.totalItem}>
                  <Text style={styles.tableNumber}>{reportData?.data?.length}</Text>
                  <Text style={styles.tableAddress}></Text>
                  <Text style={styles.tableBr}>{reportData?.totalBedrooms}</Text>
                  <Text style={styles.tableBa}></Text>
                  {/*<Text style={styles.tableRent}>*/}
                  {/*  ${formatNumber(reportData?.totalCurrentRent)}*/}
                  {/*</Text>*/}
                  <Text style={styles.tableRent}>
                    ${formatNumber(reportData?.totalAvarageRent)}
                  </Text>
                </View>
              </View>
            </>
          ) : reportData?.data?.some((element) => element.isComplete === false) ? (
            <Text style={styles.errMessage}>There is no data for the address currently</Text>
          ) : (
            <>
              <View style={styles.multiAddress}>
                <Text>
                  <Text style={styles.multiAddressBold}>Address:</Text>{' '}
                  {reportData?.data &&
                    typeof reportData.data[0].address === 'string' &&
                    reportData.data[0].address}
                </Text>
              </View>
              <View style={styles.lables}>
                <Text style={styles.multiLabelNumber}>№</Text>
                <Text style={styles.multiLabelAddress}>Unit Description</Text>
                <Text style={styles.multiLabelBr}>BR</Text>
                <Text style={styles.multiLabelBa}>BA</Text>
                <Text style={styles.multiLabelRent}>Average Rent</Text>
                <Text style={styles.multiLabelCount}>Number of Units</Text>
                <Text style={styles.multiLabelTrent}>Total Rent</Text>
              </View>
              <View style={styles.table}>{multiReport}</View>
              <View>
                <View style={styles.lables}>
                  <Text style={styles.labelNumber}>Total</Text>
                </View>
                <View style={styles.totalItem}>
                  <Text style={styles.multiLabelNumber}>{reportData?.data?.length}</Text>
                  <Text style={styles.multiLabelAddress}></Text>
                  <Text style={styles.multiLabelBr}>{reportData?.totalBedrooms}</Text>
                  <Text style={styles.multiLabelBa}></Text>
                  <Text style={styles.multiLabelRent}>
                    ${formatNumber(reportData?.totalAvarageRent)}
                  </Text>
                  <Text style={styles.multiLabelCount}>{formatNumber(reportData?.totalCount)}</Text>
                  <Text style={styles.multiLabelTrent}>${formatNumber(reportData?.totalRent)}</Text>
                </View>
              </View>
            </>
          )}
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      className={classNameBtn}
      document={pdf}
      fileName={`${generateTitle()}.pdf`}
      style={{ textDecoration: 'none' }}>
      {children}
    </PDFDownloadLink>
  );
};

export default ResultTableDownload;

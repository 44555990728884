import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import Modal from 'react-modal';
import classNames from 'classnames';

import NearbyCompleteReportInput from '../../../components/NearbyComps/NearbyCompsReportInput/NearbyCompleteReportInput.component';
import NearbyCompleteResult from '../../../components/NearbyComps/NearbyCompsResult/NearbyCompleteResult.component';

import {
  getAuthInfoRequest,
  resetNearbyReport,
  updateNearbyCalculateResult
} from '../../../store/actions';
import { IRootState, INearbyFetchData } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';
import { customStyles } from './NearbyCompsReport.data';
import styles from './NearbyCompsReport.module.scss';

import Background from '../../../assets/images/nearbyComps/nearbyBg.png';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';

type Props = {
  openApiModal: () => void;
};

const NearbyCompsReport = ({ openApiModal }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isOpenProcessModal, setIsOpenProcessModal] = useState(false);
  const [data, setData] = useState([]);

  const user = useSelector((state: IRootState) => state.authReducer);
  const { id, data: dataForRequest } = useSelector((state: IRootState) => state.nearbyCompsReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const nearbyId = params.id;

  useEffect(() => {
    if (nearbyId !== 'new') {
      const fetchData = async () => {
        setIsLoading(true);
        const response = await fetch(
          `${ENVS.PROD}/nearby_comps/findOne?id=${user.apiKey}&reportId=${nearbyId}`
        );
        const data = await response.json();
        return data[0];
      };
      fetchData()
        .then((res) => {
          setData(res);
          dispatch(
            updateNearbyCalculateResult({
              address: res.summary.address,
              bedrooms: res.summary.bedrooms,
              bathrooms: res.summary.bathrooms,
              size: res.summary.size,
              currentRent: res.summary.currentRent,
              type: res.summary.type
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsReportLoaded(true);
          setIsLoading(false);
        });
    }
  }, [nearbyId]);

  useEffect(() => {
    dispatch(getAuthInfoRequest({ authKey: user.apiKey }));
  }, []);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const openProcessModal = () => setIsOpenProcessModal(true);
  const closeProcessModal = () => setIsOpenProcessModal(false);

  const onCalculate = async () => {
    try {
      closeProcessModal();
      setIsLoading(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id,
          createdAt: new Date().toISOString(),
          title: dataForRequest.address.label,
          dataForRequest
        })
      };
      const response = await fetch(
        `${ENVS.PROD}/nearby_comps/create/${user.apiKey}`,
        requestOptions
      );
      if (response.ok) {
        const responceMsg = await response.json();
        navigate(`/nearby-comps/report/${id}`);
      } else {
        setIsRequestError(true);
      }
    } catch (error) {
      setIsRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMainButton = () => {
    if (!isReportLoaded) {
      openProcessModal();
    } else {
      const newId = uuid();
      dispatch(resetNearbyReport({ newId }));
      setIsReportLoaded(false);
      setData([]);
      navigate(`/nearby-comps/report/new`);
      setIsRequestError(false);
    }
  };

  const lackOfAPIkeyMessage = user.authorized ? null : (
    <div className={styles.creditsAvailabilityContainer}>
      <span className={styles.creditsAvailabilityText}>
        To run the report, please enter the API key.
        <span onClick={openApiModal} className={styles.creditsAvailabilityLink}>
          Set API key
        </span>
      </span>
    </div>
  );

  const lackOfCreditsMessage =
    user.credits_remaining === 0 ? null : (
      <div className={styles.creditsAvailabilityContainer}>
        <span className={styles.creditsAvailabilityText}>
          The number of items exceeds the available credits.
          <a
            href="https://www.rentometer.com/accounts/edit"
            target="_blank"
            rel="noreferrer"
            className={styles.creditsAvailabilityLink}>
            Buy More Credits
          </a>
        </span>
      </div>
    );

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${Background})`
      }}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.titleText}>Nearby Comps</h2>
            <div className={styles.buttonsContainer}>
              <button className={styles.viewMyReports} onClick={() => navigate('/nearby-comps')}>
                View My Reports
              </button>
              <div className={styles.buildReportButtonContainer}>
                <button
                  className={classNames(
                    isReportLoaded ? styles.buildReportButtonLoaded : styles.buildReportButton
                  )}
                  onClick={handleMainButton}
                  disabled={
                    !isReportLoaded &&
                    !(
                      dataForRequest.address &&
                      dataForRequest.bedrooms &&
                      dataForRequest.bathrooms &&
                      dataForRequest.size &&
                      dataForRequest.currentRent &&
                      dataForRequest.type
                    )
                  }>
                  {isReportLoaded ? 'Search Again' : 'Calculate'}
                </button>
                <Modal
                  isOpen={isOpenProcessModal}
                  onRequestClose={closeProcessModal}
                  style={customStyles}>
                  <CloseSvg
                    style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
                    onClick={closeProcessModal}
                  />
                  <h1 className={styles.processModalTitle}>Start process</h1>
                  <span className={styles.processModalDescription}>
                    Your Premium credit balance: {user.premium_credits_remaining}
                  </span>
                  <span className={styles.processModalDescription}>
                    Process cost: 1 Premium credit
                  </span>
                  <div className={styles.modalButtonContainer}>
                    <button onClick={closeProcessModal} className={styles.closeProcessModalButton}>
                      Cancel
                    </button>
                    <button onClick={onCalculate} className={styles.confirmProcessModalButton}>
                      Confirm
                    </button>
                  </div>
                </Modal>
                <span className={styles.hide}>
                  {user.authorized
                    ? 'Please fill empty addresses or delete them'
                    : 'Please set the API key to start the report'}
                </span>
              </div>
            </div>
          </div>
          <NearbyCompleteReportInput />
          {lackOfAPIkeyMessage}
          {/* {user.authorized ? lackOfCreditsMessage : null} */}
          {isRequestError ? (
            <div style={{ margin: '150px 0', fontWeight: '500', fontSize: '22px' }}>
              Something went wrong, try again later.
            </div>
          ) : null}
          {isLoading ? (
            <div style={{ margin: '150px 0' }} className="spiner-container">
              <img src={spiner} alt="loading.." />
            </div>
          ) : isReportLoaded && data && data['id'] ? (
            <NearbyCompleteResult data={data} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NearbyCompsReport;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

import { IRootState, INearbyFetchData } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import { CompletedReportsTableRent } from '../../../components/RentComps/CompletedReportsTableRent/CompletedReportsTableRent.component';
import FAQComponent from '../../../components/FAQComponent/FAQComponent.component';
import { rentCompsFAQ } from '../../../components/FAQComponent/FAQComponent.data';
import TitleBadge from '../../../components/TitleBadge/TitleBadge.component';

import styles from './CompletedReportsRent.module.scss';
import Background from '../../../assets/images/nearbyComps/nearbyBg.png';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';

import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CompletedReportsRent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<INearbyFetchData[] | []>([]);
  const [deleteReport, setDeleteReport] = useState('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showLessItems: true
    }
  });

  const navigate = useNavigate();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    const fetchData = () => {
      if (tableParams?.pagination?.current && keyIsLoaded) {
        setIsLoading(true);
        fetch(`${ENVS.PROD}/rent_comps/findOffset/${apiKey}?page=${tableParams.pagination.current}`)
          .then((res) => res.json())
          .then((results) => {
            if (results.reports) {
              setData(results.reports);
              setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: results.totalReports
                }
              });
            }
            setIsLoading(false);
          });
      }
    };

    try {
      fetchData();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }, [JSON.stringify(tableParams.pagination?.current), deleteReport]);

  const rows = data.map((item) => {
    return { ...item, key: item.id };
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter
    });
  };

  const handleNewGprReportClick = () => {
    navigate(`report/new`);
  };

  const handleDeleteReport = useCallback((id) => {
    setDeleteReport(id);
  }, []);

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${Background})`
        }}>
        <div className={styles.contentContainer}>
          <div className={styles.contentHeader}>
            <div className={styles.contentHeaderContainer}>
              <h2 className={styles.contentHeaderTitle}>
                Rentometer Tools: Rent Comp Report
                <TitleBadge text={'BETA'} />
              </h2>
              <div className={styles.contentHeaderSubTitle}>
                To receive all available rent comps, enter an address and then select the property
                type and number of bedrooms.
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.headerContainer}>
                <h1 className={styles.statusTitle}>Completed Rent Comp Reports</h1>
              </div>
              <button className={styles.headerButton} onClick={handleNewGprReportClick}>
                Build New Report
              </button>
            </div>

            {isLoading ? (
              <div style={{ margin: '150px 0' }} className="spiner-container">
                <img src={spiner} alt="loading.." />
              </div>
            ) : (
              <CompletedReportsTableRent
                rows={rows}
                columns={null}
                handleDeleteReport={handleDeleteReport}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classNames(styles.container, styles.grayContainer)}>
        <div className={styles.contentContainer}>
          <FAQComponent data={rentCompsFAQ} />
        </div>
      </div>
    </>
  );
};
export default CompletedReportsRent;

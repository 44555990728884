import { createAction } from 'redux-actions';

export const SET_API_KEY = 'SET_API_KEY';

export const GET_AUTH_INFO_REQUEST = 'GET_AUTH_INFO_REQUEST';
export const GET_AUTH_INFO_SUCCESS = 'GET_AUTH_INFO_SUCCESS';
export const GET_AUTH_INFO_FAILURE = 'GET_AUTH_INFO_FAILURE';

export const UPDATE_TOOLS_TYPE = 'UPDATE_TOOLS_TYPE';

export const UPDATE_BATCH_ANALYSIS = 'UPDATE_BATCH_ANALYSIS';
export const ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS';
export const DELETE_ADDRESSES = 'DELETE_ADDRESSES';
export const DELETE_ALL_ADDRESSES = 'DELETE_ALL_ADDRESSES';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_ADDRESS_FIELDS = 'UPDATE_ADDRESS_FIELDS';
export const UPDATE_ADDRESS_GLOBAL_FIELD = 'UPDATE_ADDRESS_GLOBAL_FIELD';
export const START_BUILD_REPORT = 'START_BUILD_REPORT';
export const BUILD_REPORT_REQUEST = 'BUILD_REPORT_REQUEST';
export const COPY_REPORT = 'COPY_REPORT';
export const CREATE_REPORT = 'CREATE_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FORMAT_ADRESSES_BEFORE_BUILD = 'FORMAT_ADRESSES_BEFORE_BUILD';

export const CHANGE_REPORT_TYPE = 'CHANGE_REPORT_TYPE';
export const ADD_NEW_ROW = 'ADD_NEW_ROW';
export const DELETE_ROW = 'DELETE_ROW';
export const RESET_REPORT = 'RESET_REPORT';
export const DUPLICATE_ROW = 'DUPLICATE_ROW';
export const UPDATE_MULTI_ADDRESS = 'UPDATE_MULTI_ADDRESS';
export const UPDATE_INPUT_FIELD = 'UPDATE_INPUT_FIELD';
export const UPDATE_CALCULATE_RESULT = 'UPDATE_CALCULATE_RESULT';

export const SET_REPORT = 'SET_REPORT';
export const RESET_NEARBY_REPORT = 'RESET_NEARBY_REPORT';
export const UPDATE_NEARBY_INPUT_FIELD = 'UPDATE_NEARBY_INPUT_FIELD';
export const UPDATE_NEARBY_CALCULATE_RESULT = 'UPDATE_NEARBY_CALCULATE_RESULT';
export const UPDATE_REPORT_ID = 'UPDATE_REPORT_ID';
export const CLEAR_REPORT = 'CLEAR_REPORT';

export const setApiKey = createAction(SET_API_KEY);

export const getAuthInfoRequest = createAction(GET_AUTH_INFO_REQUEST);
export const getAuthInfoSuccess = createAction(GET_AUTH_INFO_SUCCESS);
export const getAuthInfoFailure = createAction(GET_AUTH_INFO_FAILURE);

export const updateToolsType = createAction(UPDATE_TOOLS_TYPE);

export const updateBatchAnalysis = createAction(UPDATE_BATCH_ANALYSIS);
export const addNewAddress = createAction(ADD_NEW_ADDRESS);
export const deleteAddresses = createAction(DELETE_ADDRESSES);
export const deleteAllAddresses = createAction(DELETE_ALL_ADDRESSES);
export const updateTitle = createAction(UPDATE_TITLE);
export const updateAddressFields = createAction(UPDATE_ADDRESS_FIELDS);
export const updateAddressGlobalField = createAction(UPDATE_ADDRESS_GLOBAL_FIELD);
export const startBuildReport = createAction(START_BUILD_REPORT);
export const buildReportRequest = createAction(BUILD_REPORT_REQUEST);
export const copyReport = createAction(COPY_REPORT);
export const createReport = createAction(CREATE_REPORT);
export const deleteReport = createAction(DELETE_REPORT);
export const fetchReports = createAction(FETCH_REPORTS);
export const formatAdressesBeforeBuild = createAction(FORMAT_ADRESSES_BEFORE_BUILD);

export const changeReportType = createAction(CHANGE_REPORT_TYPE);
export const addNewRow = createAction(ADD_NEW_ROW);
export const deleteRow = createAction(DELETE_ROW);
export const resetReport = createAction(RESET_REPORT);
export const duplicateRow = createAction(DUPLICATE_ROW);
export const updateMultiAddress = createAction(UPDATE_MULTI_ADDRESS);
export const updateInputField = createAction(UPDATE_INPUT_FIELD);
export const updateCalculateResult = createAction(UPDATE_CALCULATE_RESULT);

export const setReport = createAction(SET_REPORT);
export const resetNearbyReport = createAction(RESET_NEARBY_REPORT);
export const updateNearbyInputField = createAction(UPDATE_NEARBY_INPUT_FIELD);
export const updateNearbyCalculateResult = createAction(UPDATE_NEARBY_CALCULATE_RESULT);
export const updateReportId = createAction(UPDATE_REPORT_ID);
export const clearReport = createAction(CLEAR_REPORT);

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { deleteReport } from '../../../store/actions';
import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import 'rc-dropdown/assets/index.css';
import styles from './CompletedReportsDropdownGPR.module.scss';

import { ReactComponent as AdditionalSvg } from '../../../assets/images/gpr/additional.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';

export const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const CompletedReportsDropdownGPR = ({ report, handleDeleteReport }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const handleViewReports = (id?: string) => navigate(`/gpr-calculator/result/${id}`);

  const generateTitle = () => {
    let date = new Date();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    return `GPR_${year}${month}${day}_${hour}${minute}${second}`;
  };

  const handleDownloadCSV = () => {
    const dataToParse = report.data?.map((item) => {
      const { address, bedrooms, bathrooms, quickview_url } = item;
      const commonFields = { address, bedrooms, bathrooms, url: quickview_url };
      if (report.reportType === 'single') {
        return { ...commonFields, current_rent: item.currentRent, avarage_rent: item.mean };
      } else {
        const { description, mean, count } = item;
        return {
          ...commonFields,
          unit_description: description,
          rent: mean,
          number_of_units: count,
          total_rent: mean * count
        };
      }
    });
    const {
      reportType,
      totalBedrooms,
      totalCurrentRent,
      totalAvarageRent,
      totalCount,
      multiTotalRent
    } = report;
    const totalToParse =
      reportType === 'single'
        ? {
            address: 'Total',
            bedrooms: totalBedrooms,
            current_rent: totalCurrentRent,
            avarage_rent: totalAvarageRent
          }
        : {
            address: 'Total',
            bedrooms: totalBedrooms,
            rent: totalAvarageRent,
            number_of_units: totalCount,
            total_rent: multiTotalRent
          };
    const csvData = [...dataToParse, totalToParse];
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${generateTitle()}.csv`);
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleConfirmModal = async () => {
    if (keyIsLoaded) {
      const data = await fetch(`${ENVS.PROD}/gpr/deleteGpr/${apiKey}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: report.id })
      });
      const json = await data.json();
      closeModal();
      handleDeleteReport(report.id);
    }
  };

  function onSelectOption({ key }) {
    if (key === 'view') {
      handleViewReports(report.id);
    } else if (key === 'delete') {
      openModal();
    } else if (key === 'download') {
      handleDownloadCSV();
    }
  }

  const menu = (
    <Menu
      onClick={onSelectOption}
      className={styles.modalDropdown}
      style={{
        width: '120px',
        padding: '15px 10px',
        position: 'relative',
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '5px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#303134'
      }}>
      <MenuItem style={{ marginBottom: '3px' }} key="view">
        View
      </MenuItem>
      <MenuItem style={{ marginBottom: '3px' }} key="delete">
        Delete
      </MenuItem>
      <MenuItem key="download">Download</MenuItem>
    </Menu>
  );

  return (
    <>
      <Dropdown getPopupContainer={() => document.body} trigger={['click']} overlay={menu}>
        <div className={styles.dropdownSvgContainer}>
          <AdditionalSvg width={5} height={20} />
        </div>
      </Dropdown>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}>
        <CloseSvg className={styles.closeModalIcon} onClick={() => closeModal()} />
        <h1 className={styles.modalTitle}>Delete report?</h1>
        <span className={styles.modalDescription}>
          The report will be permanently deleted. Please download CSV if you will need a copy in the
          future.
        </span>
        <div className={styles.modalButtonContainer}>
          <div className={styles.modalButtonContainerSmall}>
            <button className={styles.modalDownloadButton} onClick={handleDownloadCSV}>
              Download CSV
            </button>
            <button onClick={() => handleConfirmModal()} className={styles.modalConfirmButton}>
              Confirm
            </button>
          </div>
          <button onClick={() => closeModal()} className={styles.modalCancelButton}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CompletedReportsDropdownGPR;

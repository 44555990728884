import React from 'react';
import styles from './Header.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import { IRootState } from '../../constants/types';
import { updateToolsType } from '../../store/actions';

import { ReactComponent as RentometerLogo } from '../../assets/images/RentometerLogo.svg';

const Header = () => {
  const { apiKey } = useSelector((state: IRootState) => state.authReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMainLayoutReportClick = () => navigate('/');

  const handleCompletedReportsClick = () => {
    if (apiKey) {
      navigate('/batch-processor/new');
    } else {
      window.location.href = 'https://www.rentometer.com/batch-processor';
    }
  };

  const handleClickRentComps = () => {
    if (apiKey) {
      navigate('/rent-comps/report/new');
    } else {
      window.location.href = 'https://www.rentometer.com/rent-comps';
    }
  };

  function handleSetToolsType(type: 'market' | 'rent') {
    dispatch(updateToolsType(type));
    navigate('/');
  }

  const handleClickMakretTools = (id: string) => {
    let url = '';

    if (apiKey) {
      switch (id) {
        case 'cb':
          url = 'https://www.rentometer.com/report_brandings';
          break;
        case 'ld':
          url = 'https://www.rentometer.com/leadgen/setup';
          break;
        case 'rd':
          url = 'https://directory.rentometer.com/';
          break;
        default:
          break;
      }
    } else {
      switch (id) {
        case 'cb':
          url = 'https://www.rentometer.com/custom-branding';
          break;
        case 'ld':
          url = 'https://www.rentometer.com/leadgen';
          break;
        case 'rd':
          url = 'https://directory.rentometer.com/about';
          break;
        default:
          break;
      }
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  function handleItemClick(id: string) {
    switch (id) {
      case 'br':
        handleCompletedReportsClick();
        break;
      case 'rc':
        handleClickRentComps();
        break;
      case 'cb':
        handleClickMakretTools(id);
        break;
      case 'ld':
        handleClickMakretTools(id);
        break;
      case 'rd':
        handleClickMakretTools(id);
        break;
      case 'ew':
        handleClickMakretTools(id);
        break;
      default:
        break;
    }
  }

  const menu = (
    <div className={styles.menu}>
      <div className={styles.menuHeader}>
        <p className={styles.menuTitle} onClick={() => handleSetToolsType('rent')}>
          Rent Analysis Tools
        </p>
        <ul>
          <li key={'br'} onClick={() => handleItemClick('br')}>
            Batch Processor
          </li>
          <li key={'rc'} onClick={() => handleItemClick('rc')}>
            Rent Comp Report
          </li>
          {/* <li key={'gpr'} onClick={() => handleItemClick('gpr')}>
            GPR Calculator
          </li> */}
        </ul>
      </div>
      <div className={styles.menuHeader}>
        <p className={styles.menuTitle} onClick={() => handleSetToolsType('market')}>
          Marketing Tools
        </p>
        <ul>
          <li key={'cb'} onClick={() => handleItemClick('cb')}>
            Report Branding
          </li>
          <li key={'ld'} onClick={() => handleItemClick('ld')}>
            Lead Generation
          </li>
          <li key={'rd'} onClick={() => handleItemClick('rd')}>
            Rentometer Marketplace
          </li>
          {/* <li key={'ew'} onClick={() => handleItemClick('ew')}>
            Engagement Widget
          </li> */}
        </ul>
      </div>
    </div>
  );

  return (
    <nav className={styles.navigationBar}>
      <div className={styles.container}>
        <a href="https://rentometer.com">
          <RentometerLogo />
        </a>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonContainerItem}>
            <Dropdown
              getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
              trigger={['hover']}
              overlay={menu}
              placement="bottom">
              <button
                className={styles.completedReportsButton}
                onClick={handleMainLayoutReportClick}>
                Tools
              </button>
            </Dropdown>
          </div>
          <a
            href="https://rentometer.zendesk.com/hc/en-us/sections/13106589370260-Tools"
            rel="noreferrer"
            target="_blank"
            className={styles.completedReportsButton}>
            Help Center
          </a>
          <a className={styles.completedReportsButton} href="https://rentometer.com">
            Back to main site
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;

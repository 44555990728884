import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { addNewRow, updateInputField } from '../../../store/actions';
import Modal from 'react-modal';

import { BEDS_OPTIONS, BATHS_OPTIONS } from '../../../constants/constants';
import CustomSelect from './components/CustomSelect.component';
import CustomDropdown from './components/CustomDropdown.component';
import CustomAutocomplete from './components/CustomAutocomplete.component';

import './ReportTableGPR.scss';
import { IRootState } from '../../../constants/types';

import location from '../../../assets/images/gpr/location.svg';
import plus from '../../../assets/images/gpr/plus.svg';
import closeImage from '../../../assets/images/gpr/closeModal.svg';

const customAlertModal: Modal.Styles = {
  overlay: {
    backgroundColor: 'none'
  },
  content: {
    minWidth: '268px',
    padding: '10px 0px',
    top: '10%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center'
  }
};

const ReportTableGPR = () => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const { activeReportType } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeSingleReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeMultiReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);

  const { id: singleReportId, data: singleReportData } = activeSingleReport;
  const { id: multiReportId, data: multiReportData } = activeMultiReport;

  const dispatch = useDispatch();

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const onMaxLengthValidation = (value, id) => {
    if (value.length < 9) {
      dispatch(updateInputField({ value, id, type: 'description', activeReportType }));
    }
  };

  const openAlertModal = (value) => {
    setIsAlertModalOpen(value);
    setTimeout(closeAlertModal, 2500);
  };
  const closeAlertModal = () => setIsAlertModalOpen(false);

  const addNewElement = (e) => {
    if (activeReportType === 'single') {
      const defaultSingle = {
        id: uuid(),
        address: '',
        bedrooms: '',
        bathrooms: ''
      };

      dispatch(addNewRow({ activeReportType, defaultSingle }));
    } else if (activeReportType === 'multi') {
      const defaultMulti = {
        id: uuid(),
        description: '',
        bedrooms: '',
        bathrooms: '',
        count: ''
      };

      dispatch(addNewRow({ activeReportType, defaultMulti }));
    }
  };

  // const onUpdateCount = (value, id, type) => {
  //   dispatch(updateInputField({ value, id, type, activeReportType }));
  // };

  const onUpdateCount = (value: string, id: number, type: string) => {
    let numericValue = parseFloat(value);

    if (numericValue < 0) return;
    numericValue = parseFloat(value.replace(/^0+/, ''));

    dispatch(updateInputField({ value: numericValue, id, type, activeReportType }));
  };

  const singleReport = singleReportData.map((item, i) => {
    return (
      <div key={i} className="item">
        <div className="item__number">{i + 1}</div>
        <div className="item__address">
          <div className="item__address-label">Property Address</div>
          <CustomAutocomplete id={i} />
          <img className="item__address-image" src={location} alt="location" />
        </div>
        {/*<div className="item__currentRent">*/}
        {/*  <div className="item__currentRent-label">Current Rent</div>*/}
        {/*  <div className="item__currentRent-inputContainer">*/}
        {/*    <input*/}
        {/*      className="item__currentRent-input"*/}
        {/*      type="number"*/}
        {/*      value={singleReportData.filter((item, number) => number === i)[0].currentRent}*/}
        {/*      onChange={(e) => onUpdateCount(e.target.value, i, 'currentRent')}*/}
        {/*    />*/}
        {/*    <div className="item__currentRent-placeholder">$</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="item__bedrooms">
          <div className="item__bedrooms-label">Bedrooms</div>
          <CustomSelect id={i} type={'bedrooms'} option={BEDS_OPTIONS} />
        </div>
        <div className="item__bathrooms">
          <div className="item__bathrooms-label">Bathrooms</div>
          <CustomSelect id={i} type={'bathrooms'} option={BATHS_OPTIONS} />
        </div>
        <CustomDropdown id={i} openAlertModal={openAlertModal} />
      </div>
    );
  });

  const multiReport = multiReportData.map((item, i) => {
    return (
      <div className="item" key={i}>
        <div className="item__number">{i + 1}</div>
        <div className="item__description">
          <div className="item__description-label">Unit Description</div>
          <input
            type="text"
            name="inputValidation"
            className="item__description-input"
            onChange={(e) => onMaxLengthValidation(e.target.value, i)}
            value={multiReportData.filter((item, number) => number === i)[0].description}
          />
          <div
            className="item__description-label-bottom"
            style={{
              display:
                multiReportData.filter((item, number) => number === i)[0].description.length === 8
                  ? ''
                  : 'none'
            }}>
            Maximum 8 characters
          </div>
        </div>
        <div className="item__bed">
          <div className="item__bed-label">Bedrooms</div>
          <CustomSelect id={i} type={'bedrooms'} option={BEDS_OPTIONS} />
        </div>
        <div className="item__bath">
          <div className="item__bath-label">Bathrooms</div>
          <CustomSelect id={i} type={'bathrooms'} option={BATHS_OPTIONS} />
        </div>
        <div className="item__count">
          <div className="item__count-label">Number of Units</div>
          <input
            className="item__count-input"
            type="number"
            value={multiReportData.filter((item, number) => number === i)[0].count}
            onChange={(e) => onUpdateCount(e.target.value, i, 'count')}
          />
        </div>
        <CustomDropdown id={i} openAlertModal={openAlertModal} />
      </div>
    );
  });

  return (
    <>
      {activeReportType === 'single' ? (
        <>
          <div className="content__table">
            <div className="table__items">{singleReport}</div>
          </div>
          <div className="content__addUnit">
            {singleReportData.length < 10 ? (
              <>
                <img src={plus} alt="plus" />
                <div className="content__addUnit-text" onClick={(e) => addNewElement(e)}>
                  Add another house
                </div>
              </>
            ) : (
              <div className="content__addUnit-disabled-text">
                The number of lines is limited up to 10
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="content__table">
            <div className="address__input">
              <div className="address__label">Property Address</div>
              <CustomAutocomplete />
              <img className="address__image" src={location} alt="location" />
            </div>
            <div className="table__items">{multiReport}</div>
          </div>
          <div className="content__addUnit">
            {multiReportData.length < 10 ? (
              <>
                <img src={plus} alt="plus" />
                <div className="content__addUnit-text" onClick={(e) => addNewElement(e)}>
                  Add another unit
                </div>
              </>
            ) : (
              <div className="content__addUnit-disabled-text">
                The number of lines is limited up to 10
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        isOpen={isAlertModalOpen}
        onRequestClose={closeAlertModal}
        style={customAlertModal}
        shouldCloseOnOverlayClick={true}>
        <h1 className="popup-message">Row is deleted</h1>
        <img
          src={closeImage}
          alt="close modal image"
          onClick={closeAlertModal}
          style={{
            width: '20px',
            marginLeft: '20px',
            cursor: 'pointer'
          }}
        />
      </Modal>
    </>
  );
};

export default ReportTableGPR;

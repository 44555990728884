import React from 'react';
import dateFormat from 'dateformat';
import { Table } from 'antd';
import styles from './CompletedReportsTableGPR.module.scss';
import CompletedReportsDropdownGPR from '../CompletedReportsDropdownGPR/CompletedReportsDropdownGPR.component';

export const CompletedReportsTableGPR = ({
  rows,
  columns,
  handleDeleteReport,
  pagination,
  onChange
}) => {
  const customColumns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      minWidth: 250,
      showSorterTooltip: false,
      sorter: (a, b) => b.title.trim().toLowerCase().localeCompare(a.title.trim().toLowerCase()),
      sortDirections: ['descend'],
      render: (item: string) => {
        return <div style={{ marginLeft: '18px' }}>{item}</div>;
      }
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'reportType',
      align: 'left',
      width: 100,
      render: (item: string) => {
        return <div style={{ textTransform: 'capitalize' }}>{item?.toLowerCase()} </div>;
      }
    },
    {
      title: 'Date Completed',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'left',
      width: 280,
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend'],
      defaultSortOrder: 'descend',
      render: (item: string) => {
        return dateFormat(item, 'mmmm dS, yyyy h:MM:ss TT');
      }
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 70,
      render: (item: string) => {
        return (
          <div className={styles.actions}>
            <CompletedReportsDropdownGPR report={item} handleDeleteReport={handleDeleteReport} />
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Table
        locale={{
          emptyText: 'No Processed Reports'
        }}
        columns={columns || customColumns}
        dataSource={[...rows]}
        pagination={pagination}
        onChange={onChange}
        className={styles.resultTable}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export const customStyles: object = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: 'none',
    boxShadow: 'none',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '25px',
    color: '#2D3035'
  }),
  menu: (base) => ({
    ...base,
    border: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    width: 'max-content',
    minWidth: '100%',
    maxHeight: '167px',
    overflowY: 'scroll',
    padding: '13px 13px 11px 13px',
    right: '0px'
  }),
  option: () => ({
    borderRadius: '5px',
    textAlign: 'left',
    marginBottom: '2px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#303134',
    paddingLeft: '3px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F7FB'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    paddingRight: '12px',
    cursor: 'pointer'
  })
};

import React, { useState, useCallback } from 'react';
import Select from 'react-select';

import { FILTER_MULTI_OPTIONS, FILTER_SINGLE_OPTIONS } from '../../../constants/constants';

import './ResultTableGPR.scss';
import { customStyles } from './ReportTableGPR.data';

import { DropdownIndicator } from '../ReportTableGPR/components/CustomSelect.component';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyLinkSvg } from '../../../assets/images/CopyLink.svg';

const ReportTableGPR = ({ reportData }) => {
  const [activeLabel, setActiveLabel] = useState<string>('BA');

  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');

  const onFilter = useCallback(
    (value) => {
      if (reportData?.reportType === 'single') {
        setActiveLabel(
          FILTER_SINGLE_OPTIONS.filter((item) => {
            return item.value === value;
          })[0].value
        );
      } else {
        setActiveLabel(
          FILTER_MULTI_OPTIONS.filter((item) => {
            return item.value === value;
          })[0].value
        );
      }
    },
    [reportData]
  );

  const singleReportElements = reportData?.data?.map((element, i) => {
    return (
      <div
        key={i}
        className={`resultTable__item resultTable__item-section ${
          (i + 1) % 2 !== 0 ? 'resultTable__item-gray' : ''
        }`}>
        <div className="resultTable__order">{i + 1}</div>
        <div className="resultTable__address">
          <p>{element.address}</p>
          {element.isComplete ? (
            <p className="infoSvgContainer">
              <a href={element.quickview_url} target="_blank" rel="noreferrer">
                <CopyToClipboard text={element.quickview_url}>
                  <CopyLinkSvg className="copySvg" />
                </CopyToClipboard>
              </a>
            </p>
          ) : (
            <p className="infoSvgContainer">
              <InfoCircleOutlined className="infoSvg" />
              <div className="hide">There is no data for the address currently</div>
            </p>
          )}
        </div>
        <div className={`resultTable__bedrooms ${activeLabel === 'BR' ? '' : 'hide_item'}`}>
          {element.isComplete ? element.bedrooms : '-'}
        </div>
        <div className={`resultTable__bathrooms ${activeLabel === 'BA' ? '' : 'hide_item'}`}>
          {element.isComplete ? element.bathrooms : '-'}
        </div>
        {/*<div className={`resultTable__rent ${activeLabel === 'Current rent' ? '' : 'hide_item'}`}>*/}
        {/*  {element.isComplete && element.currentRent*/}
        {/*    ? `$${formatNumber(element.currentRent)}`*/}
        {/*    : '-'}*/}
        {/*</div>*/}
        <div className={`resultTable__rent ${activeLabel === 'Avarage rent' ? '' : 'hide_item'}`}>
          {element.isComplete ? `$${formatNumber(element.mean)}` : '-'}
        </div>
      </div>
    );
  });

  const multiReportElements = reportData?.data?.map((element, i) => {
    return (
      <div
        key={i}
        className={`resultTable__item resultTable__item-section ${
          (i + 1) % 2 !== 0 ? 'resultTable__item-gray' : ''
        }`}>
        <div className="resultTableMulti__order">{i + 1}</div>
        <div className="resultTableMulti__address">
          <p>{element.description}</p>
          {element.isComplete ? (
            <p className="infoSvgContainer">
              <a href={element.quickview_url} target="_blank" rel="noreferrer">
                <CopyToClipboard text={element.quickview_url}>
                  <CopyLinkSvg className="copySvg" />
                </CopyToClipboard>
              </a>
            </p>
          ) : (
            <p className="infoSvgContainer">
              <InfoCircleOutlined className="infoSvg" />
              <div className="hide">There is no data for the address currently</div>
            </p>
          )}
        </div>
        <div className={`resultTableMulti__bedrooms ${activeLabel === 'BR' ? '' : 'hide_item'}`}>
          {element.isComplete ? element.bedrooms : '-'}
        </div>
        <div className={`resultTableMulti__bathrooms ${activeLabel === 'BA' ? '' : 'hide_item'}`}>
          {element.isComplete ? element.bathrooms : '-'}
        </div>
        <div
          className={`resultTableMulti__rent ${activeLabel === 'Avarage rent' ? '' : 'hide_item'}`}>
          ${formatNumber(element.mean)}
        </div>
        <div className={`resultTableMulti__count ${activeLabel === 'Count' ? '' : 'hide_item'}`}>
          {element.isComplete ? element.count : '-'}
        </div>
        <div
          className={`resultTableMulti__total ${activeLabel === 'Total rent' ? '' : 'hide_item'}`}>
          ${formatNumber(element.multiTotalRent)}
        </div>
      </div>
    );
  });

  const singleReportResult = () => {
    return (
      <>
        <div className="resultTable__content">
          <div className="resultTable__item resultTable__item-label">
            <div className="resultTable__order">№</div>
            <div className="resultTable__address">Address</div>
            <div className="resultTable__filter">
              <Select
                isClearable={false}
                isSearchable={false}
                options={FILTER_SINGLE_OPTIONS}
                placeholder=""
                styles={customStyles}
                components={{ DropdownIndicator }}
                onChange={(e) => onFilter(e?.value)}
                defaultValue={FILTER_SINGLE_OPTIONS[0]}
              />
            </div>
            <div className="resultTable__bedrooms hide_lable">BR</div>
            <div className="resultTable__bathrooms hide_lable">BA</div>
            {/*<div className="resultTable__rent hide_lable">Current Rent</div>*/}
            <div className="resultTable__rent hide_lable">Average Rent</div>
          </div>
          {singleReportElements}
        </div>
        <div className="resultTable__total">
          <div className="resultTable__item resultTable__item-label">
            <div className="resultTable__order">Totals</div>
          </div>
          <div className="resultTable__item resultTable__item-darkGray resultTable__item-section">
            <div className="resultTable__order">{reportData?.data?.length}</div>
            <div className="resultTable__address"></div>
            <div className={`resultTable__bedrooms ${activeLabel === 'BR' ? '' : 'hide_item'}`}>
              {reportData?.totalBedrooms}
            </div>
            <div
              className={`resultTable__bathrooms ${activeLabel === 'BA' ? '' : 'hide_item'}`}></div>
            {/*<div*/}
            {/*  className={`resultTable__rent ${activeLabel === 'Current rent' ? '' : 'hide_item'}`}>*/}
            {/*  ${formatNumber(reportData?.totalCurrentRent)}*/}
            {/*</div>*/}
            <div
              className={`resultTable__rent ${activeLabel === 'Avarage rent' ? '' : 'hide_item'}`}>
              ${formatNumber(reportData?.totalAvarageRent)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const multiReportResult = () => {
    return (
      <>
        <div className="resultTable__header">
          <span>Address:</span>
          {reportData && reportData.data && reportData?.data[0].address}
        </div>
        {reportData?.data?.some((element) => element.isComplete === false) ? (
          <div className="resultTable__message">There is no data for the address currently</div>
        ) : (
          <>
            <div className="resultTable__content">
              <div className="resultTable__item resultTable__item-label">
                <div className="resultTableMulti__order">№</div>
                <div className="resultTableMulti__address">Unit Description</div>
                <div className="resultTableMulti__filter">
                  <Select
                    isClearable={false}
                    isSearchable={false}
                    options={FILTER_MULTI_OPTIONS}
                    placeholder=""
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    onChange={(e) => onFilter(e?.value)}
                    defaultValue={FILTER_MULTI_OPTIONS[0]}
                  />
                </div>
                <div className="resultTableMulti__bedrooms hide_lable">BR</div>
                <div className="resultTableMulti__bathrooms hide_lable">BA</div>
                <div className="resultTableMulti__rent hide_lable">Average Rent</div>
                <div className="resultTableMulti__count hide_lable">Number of Units</div>
                <div className="resultTableMulti__total hide_lable">Total Rent</div>
              </div>
              {multiReportElements}
            </div>
            <div className="resultTable__total">
              <div className="resultTable__item resultTable__item-label">
                <div className="resultTableMulti__order">Totals</div>
                <div className="resultTableMulti__address"></div>
                <div className="resultTableMulti__bedrooms"></div>
                <div className="resultTableMulti__bathrooms"></div>
                <div className="resultTableMulti__rent"></div>
                <div className="resultTableMulti__count"></div>
                <div className="resultTableMulti__total"></div>
              </div>
              <div className="resultTable__item resultTable__item-darkGray resultTable__item-section">
                <div className="resultTableMulti__order">{reportData?.data?.length}</div>
                <div className="resultTableMulti__address"></div>
                <div
                  className={`resultTableMulti__bedrooms ${
                    activeLabel === 'BR' ? '' : 'hide_item'
                  }`}>
                  {reportData?.totalBedrooms}
                </div>
                <div
                  className={`resultTableMulti__bathrooms ${
                    activeLabel === 'BA' ? '' : 'hide_item'
                  }`}></div>
                <div
                  className={`resultTableMulti__rent ${
                    activeLabel === 'Avarage rent' ? '' : 'hide_item'
                  }`}>
                  ${formatNumber(reportData?.totalAvarageRent)}
                </div>
                <div
                  className={`resultTableMulti__count ${
                    activeLabel === 'Count' ? '' : 'hide_item'
                  }`}>
                  {reportData?.totalCount}
                </div>
                <div
                  className={`resultTableMulti__total ${
                    activeLabel === 'Total rent' ? '' : 'hide_item'
                  }`}>
                  ${formatNumber(reportData?.totalRent)}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const single = singleReportResult();
  const multi = multiReportResult();

  return (
    <div className="result__table">{reportData?.reportType === 'single' ? single : multi}</div>
  );
};

export default ReportTableGPR;
